'use client';
import React, { useState } from 'react';
import * as Styled from './styled';

import { IRepository, ISearchResponse, IUser } from '@/types';
import { collaboratorSearch, search } from '@/lib/searchApi';
import AsyncSelect from 'react-select/async';
import {
  components,
  ControlProps,
  GroupHeadingProps,
  OptionProps,
  SingleValueProps,
  InputProps,
  SingleValue as TSingleValue,
} from 'react-select';
import Highlight from '@/ui/Highlight';
import * as Icons from '@/ui/Icon';
import { RepositoryDataTypesWithSize } from '@/components/Repository/RepositoryDataTypes';

interface ISearchResponseWithTermAndType extends ISearchResponse {
  searchTerm?: string;
  resultType?: string;
}

interface ResultIconProps {
  resultType: string | undefined;
}

const ResultIcon: React.FC<ResultIconProps> = ({ resultType }) => {
  switch (resultType) {
    case 'repository':
      return <Icons.Book />;
    case 'user':
      return <Icons.User />;
    case 'organization':
      return <Icons.Building />;
    default:
      return null;
  }
};

const SingleValue = ({ data, ...otherProps }: SingleValueProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.SingleValue data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <ResultIcon resultType={data.resultType} />
          <span>
            <Highlight className="result-highlight" searchVal={data.searchTerm}>
              {data.namespace + (data.name ? '/' + data.name : '')}
            </Highlight>
          </span>
        </div>
        <div>
          {data.resultType === 'repository' && (
            <RepositoryDataTypesWithSize repository={data as IRepository} sizeInBytes={(data as IRepository).size} />
          )}
        </div>
      </Styled.OptionValue>
    </components.SingleValue>
  );
};

const GroupHeading = (props: GroupHeadingProps<ISearchResponseWithTermAndType>) => (
  <div style={{ borderBottom: '1px solid black', color: 'black', paddingBottom: 5, fontWeight: 'bold' }}>
    <components.GroupHeading {...props} />
  </div>
);

const SearchOption = ({ data, ...otherProps }: OptionProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.Option data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <ResultIcon resultType={data.resultType} />
          <span>
            <Highlight className="result-highlight" searchVal={data.searchTerm}>
              {data.namespace + (data.name ? '/' + data.name : '')}
            </Highlight>
          </span>
        </div>
        <div>
          {data.resultType === 'repository' && (
            <RepositoryDataTypesWithSize repository={data as IRepository} sizeInBytes={(data as IRepository).size} />
          )}
        </div>
      </Styled.OptionValue>
    </components.Option>
  );
};

const Control = ({
  children,
  prefix,
  ...props
}: ControlProps<ISearchResponseWithTermAndType> & { prefix?: React.ReactNode }) => {
  return (
    <components.Control {...props}>
      <span style={{ paddingLeft: '10px' }}>{prefix}</span>
      {children}
    </components.Control>
  );
};

export const MainSearch: React.FC<{
  query?: string;
  selection?: any;
  placeholder?: string;
  helpText?: string;
  instanceId: string;
  error?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}> = ({
  query,
  selection,
  placeholder = 'Enter Something to Search for',
  helpText = 'Enter Something to Search for',
  instanceId,
  autoFocus = false,
  disabled = false,
  error = false,
  ...props
}) => {
  const selectionChanged = (option?: TSingleValue<ISearchResponseWithTermAndType>) => {
    const win: Window = window;
    win.location = `/${option?.namespace}/${option?.name}`;
  };

  const optionLoader = async (term: string) => {
    const { repositories, organizations, users } = await search(term);

    // Mapping each repository to include searchTerm in its object
    const repositoriesWithOptions =
      repositories?.map((repository: ISearchResponseWithTermAndType) => ({
        searchTerm: term,
        resultType: 'repository',
        ...repository,
      })) || [];

    const organizationsWithOptions =
      organizations?.map((organization: any) => ({
        searchTerm: term,
        resultType: 'organization',
        ...organization,
      })) || [];

    const usersWithOptions =
      users?.map((user: any) => ({
        searchTerm: term,
        resultType: 'user',
        ...user,
      })) || [];

    const options = [
      {
        label: 'Repositories',
        options: repositoriesWithOptions,
      },
      {
        label: 'Organizations',
        options: organizationsWithOptions,
      },
      {
        label: 'Users',
        options: usersWithOptions,
      },
    ];

    return options;
  };

  return (
    <Styled.Search>
      <AsyncSelect
        styles={{
          menuList: (base) => ({
            ...base,
            maxHeight: '80vh',
          }),
        }}
        maxMenuHeight={1000}
        isDisabled={disabled}
        autoFocus={autoFocus}
        escapeClearsValue
        instanceId={instanceId}
        placeholder={placeholder}
        className="multisearch-select-container"
        classNamePrefix="multisearch-select"
        components={{
          Option: SearchOption,
          SingleValue,
          Control: (controlProps) => <Control {...controlProps} prefix={<Icons.Search />} />,
          GroupHeading,
        }}
        loadOptions={(q) => optionLoader(q)}
        onChange={(v) => selectionChanged(v as TSingleValue<ISearchResponseWithTermAndType>)}
        noOptionsMessage={(t) => (t.inputValue ? `No match for "${t.inputValue}" found` : helpText)}
        {...props}
      />
    </Styled.Search>
  );
};

const SingleValueCollaborator = ({ data, ...otherProps }: SingleValueProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.SingleValue data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <span style={{ fontWeight: '550', background: 'white' }}>{data.namespace}</span>
        </div>
      </Styled.OptionValue>
    </components.SingleValue>
  );
};

const SearchOptionCollaborator = ({ data, ...otherProps }: OptionProps<ISearchResponseWithTermAndType>) => {
  return (
    <components.Option data={data} {...otherProps}>
      <Styled.OptionValue>
        <div className="identity">
          <span>
            <Highlight className="result-highlight" searchVal={data.searchTerm}>
              {data.namespace + (data.name ? '/' + data.name : '')}
            </Highlight>
          </span>
        </div>
      </Styled.OptionValue>
    </components.Option>
  );
};

const Input = (props: InputProps<ISearchResponseWithTermAndType>) => <components.Input {...props} isHidden={false} />;

export const CollaboratorSearch: React.FC<{
  repository: IRepository;
  query?: string;
  label?: string;
  setSearchValue: (value: string) => void;
  searchValue: string;
  selection?: any;
  placeholder?: string;
  helpText?: string;
  instanceId: string;
  error?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}> = ({
  repository,
  query,
  setSearchValue,
  searchValue,
  label,
  selection,
  placeholder = 'Search by username or email',
  helpText = 'Search by username or email',
  instanceId,
  autoFocus = false,
  disabled = false,
  error = false,
  ...props
}) => {
  const selectionChanged = (option?: TSingleValue<ISearchResponseWithTermAndType>) => {
    setSearchValue(option ? option.namespace : '');
  };

  const optionLoader = async (term: string) => {
    const { users } = await collaboratorSearch(term, repository);

    const usersWithOptions =
      users?.map((user: any) => ({
        searchTerm: term,
        resultType: 'user',
        ...user,
      })) || [];

    const options = [
      {
        options: usersWithOptions,
      },
    ];

    return options;
  };

  const onInputChange = (inputValue: string, actionMeta: { action: string }) => {
    if (actionMeta.action === 'input-change') {
      setSearchValue(inputValue);
    }
  };

  return (
    <>
      <Styled.Search>
        <Styled.Label>
          {label}
          <AsyncSelect
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: '80vh',
              }),
              control: (base) => ({
                ...base,
                minWidth: '400px',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isFocused ? '#DEEBFF' : state.isSelected ? 'white' : base.backgroundColor,
                color: 'black',
                height: '100%',
              }),
            }}
            isClearable={true}
            inputValue={searchValue}
            onInputChange={onInputChange}
            controlShouldRenderValue={false}
            maxMenuHeight={1000}
            isDisabled={disabled}
            autoFocus={autoFocus}
            escapeClearsValue
            instanceId={instanceId}
            placeholder={placeholder}
            className="multisearch-select-container"
            classNamePrefix="multisearch-select"
            components={{
              DropdownIndicator: null,
              Input,
              Option: SearchOptionCollaborator,
              SingleValue: SingleValueCollaborator,
            }}
            loadOptions={(q) => optionLoader(q)}
            noOptionsMessage={({ inputValue }) => {
              return inputValue
                ? `
              The person you want to add doesn't have an Oxen account? Don't worry, use their email and we'll send them an
              invitation.
            `
                : helpText;
            }}
            onChange={(v) => selectionChanged(v as TSingleValue<ISearchResponseWithTermAndType>)}
            {...props}
          />
        </Styled.Label>
      </Styled.Search>
    </>
  );
};
