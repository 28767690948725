'use client';
import styled from 'styled-components';
import { colors, gradients } from '@/styles';

export const Avatar = styled.span<{ $size: number }>`
  position: relative;

  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  min-width: ${(props) => props.$size}px;
  min-height: ${(props) => props.$size}px;

  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  /* border: 1px solid ${colors.secondary.darkGray2}; */
  overflow: hidden;

  & > .react-gravatar {
    position: absolute;
    z-index: 1;
  }

  & > .initials {
    /* z-index: 0; */
    font-size: ${(props) => props.$size * 0.6}px;
    line-height: ${(props) => props.$size}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${gradients.blue};
    color: ${colors.primary.white};
    font-weight: bold;
    position: absolute;
    inset: 0px;
  }
`;

export const OrganizationAvatar = styled.div<{ $size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.secondary.lightGray3};
  border-radius: 10px;
  overflow: hidden;
  width: ${(props) => props.$size || 100}px;
  aspect-ratio: auto 100 / 100;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserAvatar = styled.div<{ $size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.secondary.lightGray3};
  border-radius: ${(props) => props.$size || 100}px;
  overflow: hidden;
  width: ${(props) => props.$size || 100}px;
  aspect-ratio: auto 100 / 100;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
