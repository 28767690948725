import { IOrganization, IPagination, IRepository, IUser } from '@/types';
import { fetchWrapper, extractPaginationInfo, IBaseResponse } from './apiBase';
import { BASE_API_URL } from '@/config/environment';

export interface ISearchResponse extends IBaseResponse {
  repositories?: IRepository[];
  organizations?: IOrganization[];
  users?: IUser[];
  pagination?: IPagination;
}

const responseHandler = (data: ISearchResponse): any => {
  const { repositories, organizations, users, status, status_message, error } = data;
  const pagination = extractPaginationInfo(data);
  return {
    status,
    status_message,
    repositories: repositories || [],
    organizations: organizations || [],
    users: users || [],
    pagination,
    error: error || null,
  };
};

export const search = async (searchTerm: string, token?: string | undefined): Promise<any> => {
  const targetUrl = `${BASE_API_URL}/search?search_term=${searchTerm}`;

  const data = await fetchWrapper({
    resourceName: 'search',
    targetUrl,
    authToken: token,
    resourceContext: { searchTerm: searchTerm },
  });
  return responseHandler(data);
};

export const collaboratorSearch = async (
  searchTerm: string,
  repository: IRepository,
  token?: string | undefined,
): Promise<any> => {
  const targetUrl = `${BASE_API_URL}/repos/${repository.namespace}/${repository.name}/collaborators/search?search_term=${searchTerm}`;

  const data = await fetchWrapper({
    resourceName: 'search',
    targetUrl,
    authToken: token,
    resourceContext: { searchTerm: searchTerm },
  });
  return responseHandler(data);
};
